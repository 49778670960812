application.register("comp_visual_nav", class extends Stimulus.Controller {

    get target() {
        return {
            nav: this.targets.find("nav"),
            body: this.targets.find("body")
        }
    }

    connect() {
        let self = this, element = self.element;

        self._ticking = false;
        self._offsets = {};

        cssLoaded(() => {
            let layout_offset = element.offsetTop;

            if (typeof locomotive !== "undefined" && !locomotive.options.isMobile) {
                locomotive.update();

                locomotive.on("scroll", (e) => {
                    self.onScroll(e.delta.y);
                });
            } else {
                element.nextElementSibling.style.marginTop = this.target.body.offsetHeight + this.target.nav.offsetHeight + "px";

                window.addEventListener('scroll', () => {
                    self.onScroll(window.scrollY);
                }, false);
            }

            [...document.querySelectorAll("[data-offset]")].forEach((section) => {
                let offset;
                if (window.innerWidth > 960) {
                    offset = (typeof section.dataset.offset !== "undefined") ? parseInt(section.dataset.offset) : 0;
                } else {
                    offset = 100;
                }
                self._offsets[section.getAttribute("id")] = section.offsetTop + layout_offset - offset * 2;
            });
        },1200);
    }

    disconnect() {
        let self = this;
        window.removeEventListener('scroll', () => {
            self.onScroll(window.scrollY);
        }, false);
    }

    onScroll(currentScrollY) {
        let self = this, element = self.element;

        if (!self._ticking) {
            requestAnimationFrame(() => {
                for (let i in self._offsets) {
                    if (self._offsets[i] <= currentScrollY) {
                        let active = self.target.nav.querySelector(".state--active")
                        let newActive = self.target.nav.querySelector(`[href="#${i}"]`);
                        if (active !== null) {
                            active.classList.remove("state--active");
                        }
                        if (newActive !== null) {
                            newActive.classList.add("state--active");
                            self.target.nav.closest(".wrp_comp_nav").scrollLeft = newActive.closest(".col").offsetLeft - 32;
                        }
                    }
                }

                document.querySelector("#layout_header").classList.toggle("no--shadow", currentScrollY > this.target.body.offsetHeight + document.querySelector("#layout_header").offsetHeight);
            });

            self._ticking = false;
        } else {
            self._ticking = true;
        }
    }
});